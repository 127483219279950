/**
 * HTML & Body
 * -----------
 */

body {
  color: #000a29;
  background-color: #e7f0fa;
}

*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

::-moz-selection {
  color: #fff;
  background: #96c3e9;
}

::selection {
  color: #fff;
  background: #96c3e9;
}

.theme_div {
  box-shadow: none;
}

/**
 * Typography
 * ----------
 */

hr {
  border-top-color: #cfd7e0;
}

.text-muted {
  color: #000a29 !important;
}

.txt-blue {
  color: #2185d0 !important;
}
.txt-azure {
  color: #45aaf2 !important;
}
.txt-indigo {
  color: #6435c9 !important;
}
.txt-purple {
  color: #a333c8 !important;
}
.txt-pink {
  color: #e03997 !important;
}
.txt-red {
  color: #db2828 !important;
}
.txt-orange {
  color: #f2711c !important;
}
.txt-yellow {
  color: #fbbd08 !important;
}
.txt-lime {
  color: #b5cc18 !important;
}
.txt-green {
  color: #21ba45 !important;
}
.txt-teal {
  color: #00b5ad !important;
}
.txt-cyan {
  color: #17a2b8 !important;
}
.txt-white {
  color: #fff !important;
}
.txt-gray {
  color: #4d5052 !important;
}
.txt-gray-dark {
  color: #292b30 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * Links & Buttons
 * ---------------
 */

/* Links */
a {
  color: #1f3886;
}

a:hover,
a:active,
a:focus {
  color: #1f3886;
}

.link {
  color: #1f3886 !important;
  text-decoration: underline !important;
  user-select: none;
}

.link:hover,
.link:active,
.link:focus {
  color: #1f3886 !important;
  cursor: pointer;
}

/* Buttons */
.btn {
  border: 2px solid transparent;
  border-radius: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000a29;
}

.btn:hover,
.btn:active,
.btn:focus {
  color: #1f3886;
}

.btn.btn-default {
  border-color: #1f3886;
  color: #1f3886;
  background-color: #fff;
}

.btn.btn-default:hover,
.btn.btn-default:active,
.btn.btn-default:focus {
  border-color: #000a29;
  color: #fff;
  background-color: #000a29;
}

.btn.btn-primary {
  border-color: #1f3886;
  color: #fff;
  background-color: #1f3886;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  border-color: #96c3e9;
  color: #fff;
  background-color: #96c3e9;
}

.btn.btn-white {
  color: #000000;
  background-color: #fff;
}

.btn.btn-white:hover,
.btn.btn-white:active,
.btn.btn-white:focus {
  border-color: #000a29;
  color: #fff;
  background-color: #000a29;
}

.btn-clipboard {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 1rem 1.5rem;
  border: 2px solid white;
  border-radius: 0.5em;
  font-weight: 600;
  min-width: 300px;
}

.btn-clipboard:hover,
.btn-clipboard:active {
  background-color: white;
  border: 2px solid #1f3886;
}

.btn-clipboard:active {
  background-color: rgb(239, 239, 239);
}

.btn-clipboard span {
  text-decoration: underline;
}

.btn-clipboard i {
  font-size: 1.5rem;
  margin-left: 1rem;
}

/**
 * Menus
 * -----
 */

.metismenu .g_heading {
  color: #000a29;
}

.metismenu a {
  color: #000a29;
}

.metismenu a:hover,
.metismenu a:active,
.metismenu a:focus,
.metismenu .active > a {
  letter-spacing: normal;
  border-color: #1f3886;
  color: #1f3886;
}

/**
 * Tags
 * ----
 */

.tag-primary {
  background-color: #96c3e9;
}

.tag-success {
  background-color: #28a745;
}
/**
 * Avatars
 * -------
 */

.avatar.avatar-blue {
  color: #fff;
  background-color: #1f3886;
}

/**
 * Tables
 * ------
 */

.table {
  color: #000a29;
}

.table.table-vscroll {
  min-height: 500px;
  max-height: 60vh;
  overflow: auto;
  display: inline-block;
}

.table th,
.table td {
  border-top: 0;
}

.table th {
  color: #000a29;
}

.table thead th {
  border-bottom-color: #cfd7e0;
}

.table.table-hover tbody tr:hover {
  color: #000a29;
  background-color: #cfd7e0 !important;
  cursor: pointer;
}

.table.table-striped tbody tr:nth-of-type(2n) {
  background-color: #e7f0fa;
}

.table-border {
  border: solid #bebebe 1px;
  border-radius: 0 0 0.5em 0.5em;
}

/**
 * Forms
 * -----
 */

::-webkit-input-placeholder {
  color: #cfd7e0;
}

:-ms-input-placeholder {
  color: #cfd7e0;
}

::-moz-placeholder {
  color: #cfd7e0;
}

::placeholder {
  color: #cfd7e0;
}

.form-control {
  border-color: #cfd7e0;
  border-radius: 0;
  color: #000a29;
}

.form-control:focus {
  border-color: #000a29;
  color: #000a29;
}

.form-label .text-success {
  color: #28a745 !important;
}

.form-label .text-warning {
  color: #ffc107 !important;
}

.form-label .text-primary {
  color: #1f3886 !important;
}

/**
 * Cards
 * -----
 */

.card {
  border-radius: 10px;
}

.card-header {
  padding-bottom: 20px;
  color: #fff;
  background-color: #1f3886;
}

.card-header:first-child {
  border-radius: 10px 10px 0 0;
}

/**
 * Forms
 * -----
 */

.input-group > .custom-select:not(:last-child) {
  border-radius: 0;
}

.input-group-btn > .btn {
  border-color: #fff;
}

.input-group .input-group-append .btn {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/**
 * Branding
 * --------
 */

.brand-name {
  color: #1f3886;
}

/**
 * Header
 * ------
 */

#header_top .nav-link {
  color: #000a29;
}

#header_top .nav-link:hover,
#header_top .nav-link:active,
#header_top .nav-link:focus {
  color: #1f3886;
}

#header_top .header-brand .brand-logo {
  background-color: #1f3886;
}

/**
 * Sidebar
 * -------
 */

#left-sidebar {
  z-index: 1;
}

/**
 * Page - Header
 * -------------
 */

.page-header {
  border-bottom-color: #cfd7e0;
}

.page-title {
  color: #000a29 !important;
}

/**
 * Page - Sections
 * ---------------
 */

.page .section-body {
  background-color: #e7f0fa;
}

.auth .auth_left {
  background: #1f3886;
}

/**
 * Swal - Alert Popup
 * ---------------
 */
.swal-overlay {
  z-index: 100000;
}
.swal-text {
  text-align: center;
}

/**
 * Sidebar - Overlay
 * ---------------
 */
.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 98;
}

/**
 * Document - Upload
 * ---------------
 */
.upload-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px solid grey;
  border-radius: 1rem;
  border-style: dashed;
  cursor: pointer;
  user-select: none;
}

.upload-container .fa-file-o {
  font-size: 2rem;
}

.upload-container .fa-close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -0.7rem;
  right: -0.7rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 5rem;
  color: white;
  background-color: red;
  border: 1px solid red;
  cursor: pointer;
  z-index: 10;
}

.upload-container .form-control {
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-container span {
  padding: 0 1rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.upload-container .disabled {
  opacity: 0.5;
}

/**
 * Loader for Upload Progress
 * ---------------
 */
.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
}

.loader-container svg {
  width: 100px;
}

.loader-background {
  background-color: white;
  padding: 1rem;
  border-radius: 2rem;
  border: 2px solid #e0e0e0;
}

/**
 * multiple input container for hospital verify patient email
 * ---------------
 */
.row-input-container {
  display: flex;
  flex-direction: row;
}

.row-input-container .btn {
  margin-left: 0.5rem;
}

.row-input-container i {
  font-size: 1.2rem;
}

/**
 * react modal
 * ---------------
 */
.ReactModal__Content {
  border: none !important;
  background-color: none !important;
  padding: 0 !important;
  max-width: 1000px !important;
  top: 10% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -10%) !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 9999 !important;
}

.modal-body input {
  min-width: 400px;
}

/**
 * common
 * ---------------
 */

.cursor-pointer {
  cursor: pointer;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .ReactModal__Content {
    max-width: 90% !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Large devices (desktops, more than 1200px and up) */
@media (min-width: 1200px) {
}
